import { pathToUrl, getFishingWaterUrl, getUserUrl } from './urls';
import sanitizeHtml from 'sanitize-html';

interface IBreadcrumbListItem {
  label: string;
  link?: string;
}

export interface IListItemSchema {
  description: string;
  position: number;
  url: string;
}

export interface IFAQSchema {
  name: string;
  acceptedAnswer: string;
}

const METADATA = { '@context': 'http://schema.org' };

export function validateItemsForBreadcrumbsListSchema(items: IBreadcrumbListItem[]): boolean {
  return items.slice(0, -1).every(item => item.link);
}

export function getBreadcrumbsListSchema(items: IBreadcrumbListItem[]): string {
  const json = {
    ...METADATA,
    '@type': 'BreadcrumbList',
    itemListElement: items.map(({ label, link }, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: label,
      ...(link ? { item: pathToUrl(link) } : {}), // It's okay for the last element to not have item defined (it'll use the current URL)
    })),
  };
  return JSON.stringify(json);
}

export function getBodyOfWaterSchema({
  name,
  externalId,
  latitude,
  longitude,
}: {
  name: string;
  externalId: string;
  latitude: number;
  longitude: number;
}): string {
  const json = {
    ...METADATA,
    '@type': 'BodyOfWater',
    name,
    url: getFishingWaterUrl(externalId, name),
    latitude,
    longitude,
  };
  return JSON.stringify(json);
}

export function getFAQPageSchema(faqData: IFAQSchema[]): string {
  const json = {
    ...METADATA,
    '@type': 'FAQPage',
    mainEntity: faqData.map(({ name, acceptedAnswer }) => ({
      '@type': 'Question',
      name: sanitizeHtml(name),
      acceptedAnswer: {
        '@type': 'Answer',
        text: sanitizeHtml(acceptedAnswer),
      },
    })),
  };
  return JSON.stringify(json);
}

export function getProfilePageSchema({
  fullName,
  nickname,
  avatarUrl,
}: {
  fullName: string;
  nickname: string;
  avatarUrl: string;
}): string {
  const json = {
    ...METADATA,
    '@type': 'ProfilePage',
    mainEntity: {
      ...METADATA,
      '@type': 'Person',
      description: `@${nickname} - Angler on Fishbrain.com`,
      image: {
        ...METADATA,
        '@type': 'ImageObject',
        url: avatarUrl,
      },
      url: getUserUrl(nickname),
      name: fullName,
      alternateName: nickname,
    },
  };
  return JSON.stringify(json);
}
